import io from "socket.io-client";
import PeerConnection from "../peerConnection/peerConnection";
import Streams from "../stream/Streams";
import { generalSlice } from "../../domain/SharedSlices/generalslice/generalSlice";
import { roomSlice } from "../../features/call/store/slice";
import { joinCallSlice } from "../../features/join_call/store/slice";
import { updateApprovalMembers } from "../../features/call/store/actions";
const LOCAL_URL = "localhost:3300";
const LIVE_URL = "call.workw.com"
export default class Socket {
  connection;
  #userName;
  #PeerConnections;
  #userId;
  #roomId;
  #email;
  #audioOn;
  #cameraOn;
  #screenShare;
  Streams;
  handRaisedIds;
  #dispatch;
  #naviagte;
  constructor(dispatch, naviagte) {
    this.#dispatch = dispatch;
    this.#naviagte = naviagte;
  }
  async updateCurrentStream(newStream) {
    const userId = this.#userId;

    // Update the local stream reference in Streams
    this.Streams.updateStream(userId, newStream, 'video');

    // Notify the server about the stream update
    // this.connection.emit('update-stream', {
    //   userId,
    //   stream: newStream,
    // });
  }
  async getRoomId() {
    return this.#roomId;
  }
  async getSocket() {
    this.connection = await io.connect(`https://${LIVE_URL}`);
    this.connection.on('reply_request_room_entry', async (data) => {
      console.log("reply_request_room_entry: ", data);
      if (data.admin_active) {
        this.#dispatch(updateApprovalMembers(this.#roomId))
      }
      else {
        this.#dispatch(joinCallSlice.actions.updateUserApproval(data));
      }
    });
    return this.connection.id;
  }

  async joinRoom({
    userName,
    userId,
    audioOn,
    cameraOn,
    roomId,
    email,
    audioStream,
    videoStream,
  }) {
    this.#userName = userName;
    this.#userId = userId;
    this.#roomId = roomId;
    this.#email = email;
    this.#audioOn = audioOn;
    this.#cameraOn = cameraOn;
    this.#screenShare = false;
    this.Streams = new Streams();
    this.#PeerConnections = new PeerConnection();
    this.handRaisedIds = [];
    await this.Streams.addStreams({
      userId,
      stream: videoStream,
      type: "video",
    });
    await this.Streams.addStreams({
      userId,
      stream: audioStream,
      type: "audio",
    });
    await this.#PeerConnections.setIceServers();
    await this.Events({
      type: "JOIN_ROOM",
      payload: { userName, roomId, userId, email, cameraOn, audioOn },
    });
    this.#onEvents();
  }
  async Events({ type, payload }) {
    switch (type) {
      case "JOIN_ROOM":
        await this.connection.emit("join-room", payload);
        return;
      case "SEND_TRANSCRIBE":
        await this.connection.emit("send_transcribe", payload);
        return;
      case "REQUEST_ROOM_ENTRY":
        await this.connection.emit("request_room_entry", payload);
        return;
      case "SEND_MESSAGE":
        await this.connection.emit("send_message", payload);
        return;
      case "NOTIFY_ROOM":
        await this.connection.emit("notify-room", payload);
        return;
      case "NOTIFY-RAISE-HAND":
        await this.connection.emit("notify-raise-hand", payload);
        return;
      // case "NOTIFY_NEW_USER_RAISE_HAND":
      //   await this.connection.emit("notify-new-user-raise-hand", payload);
      //   return;
      case "CREATE_OFFER":
        await this.connection.emit("createoffer", payload);
        return;
      case "CREATE_ANSWER":
        await this.connection.emit("createanswer", payload);
        return;
      case "CANDIDATE":
        await this.connection.emit("candidate", payload);
        return;
      case "LEAVE_CALL":
        await this.connection.emit("leave", payload);
        return;
      case "END-CALL-FOR-ALL":
        await this.connection.emit("end-call-for-all", payload);
        return;
      case "NOTIFY-ROOM-LOCK-UPDATE":
        await this.connection.emit("notify-room-lock-update", payload);
        return;
      case "KICK-USER":
        await this.connection.emit("kick-user", payload);
        return;
      case "SCREEN_SHARE_CREAET_OFFER":
        await this.connection.emit("screenshare_createoffer", payload);
        return;
      case "SCREEN_SHARE_CREATE_ANSWER":
        await this.connection.emit("screenshare_createanswer", payload);
        return;
      case "SCREEN_SHARE_CANDIDATE":
        await this.connection.emit("screenshare_candidate", payload);
        return;
      case "CAMERA_ON":
        await this.connection.emit("camera_on", payload);
        return;
      case "CAMERA_OFF":
        await this.connection.emit("camera_off", payload);
        return;
      case "AUDIO_ON":
        await this.connection.emit("audio_on", payload);
        return;
      case "AUDIO_OFF":
        await this.connection.emit("audio_off", payload);
        return;
      case "SCREEN_SHARE_OFF":
        await this.connection.emit("screen_off", payload);
        return;
      default:
    }
  }
  async onIceCandidate({
    userPeerConnection,
    currentUser,
    newUserId,
    roomId,
    currentUserSocket,
    newUserSocketId,
  }) {
    userPeerConnection.onicecandidate = async (event) => {
      if (!event.candidate) return;

      this.Events({
        type: "CANDIDATE",
        payload: {
          sender: currentUser,
          receiver: newUserId,
          candidate: event.candidate,
          roomId: roomId,
          senderSocketId: currentUserSocket,
          receiverSocketId: newUserSocketId,
        },
      });
    };
  }
  async #onEvents() {
    //when another user connect
    this.connection.on("user-connected", async (data) => {
      if (this.#userId == data.userId) return;
      // console.log("user coming", data);
      const { mediaConnection, payload } =
        await this.#PeerConnections.createOffer({
          currentUserName: this.#userName,
          newUserName: data.userName,
          newUserSocketId: data.socket,
          newUserId: data.userId,
          currentUser: this.#userId,
          currentUserSocket: this.connection.id,
          roomId: this.#roomId,
          myStream: await this.Streams.getUserStreams(this.#userId),
          dispatch: this.#dispatch,
          updateStream: this.Streams,
          newUserEmail: data.email,
          currentUserEmail: this.#email,
          newUserAudio: data.audioOn,
          newUserCamera: data.cameraOn,
          currentUserAudio: this.#audioOn,
          currentUserCamera: this.#cameraOn,
        });
      this.onIceCandidate({
        userPeerConnection: mediaConnection,
        currentUser: this.#userId,
        newUserId: data.userId,
        roomId: this.#roomId,
        currentUserSocket: this.connection.id,
        newUserSocketId: data.socket,
      });
      this.Events({
        type: "CREATE_OFFER",
        payload,
      });

      if (this.#screenShare) {
        const { video } = await this.Streams.getUserStreams(this.#userId + "_screen_share")
        const { screenShareConnection, payload } =
          await this.#PeerConnections.createOffer_screenShare({
            userId: this.#userId,
            socketId: this.connection.id,
            stream: video,
            roomId: this.#roomId,
            receiver: data.userId,
            receiverSocketId: data.socket,
          });
        this.onIceCandidate_screenShare({
          screenShareConnection,
          receiver: data.userId,
          receiverSocketId: data.socket,
        });
        this.Events({
          type: "SCREEN_SHARE_CREAET_OFFER",
          payload,
        });
      }
      // this.Events({
      //   type: "NOTIFY_NEW_USER_RAISE_HAND",
      //   payload: {
      //     newUserId: data.userId,
      //     roomId: this.#roomId
      //   },
      // });
      // this.Events({
      //   type: "NOTIFY-RAISE-HAND",
      //   payload: {
      //     newHandRaisedIds:this.handRaisedIds,
      //     roomId: this.#roomId
      //   },
      // });
    });
    this.connection.on('receive_message', async (data) => {
      this.#dispatch(
        roomSlice.actions.updateChat(data)
      );
    });
    this.connection.on('receive_transcribe', async (data) => {
      // console.log("receive_transcribe", data)
      this.#dispatch(roomSlice.actions.updateTranscribe(data));
    });
    this.connection.on("send-room-message", async (data) => {
      this.#dispatch(roomSlice.actions.updateNotificationMessage(data));
    });
    this.connection.on('notifying-raise-hand', async (data) => {
      this.#dispatch(generalSlice.actions.updateHandRaisedIds(data.handRaisedIds))
    });
    // this.connection.on("notifying-new-user-raise-hand", async (data) => {

    //   console.log(data, "============DATA===========", this.handRaisedIds)
    //   if (this.handRaisedIds.length) {
    //     // this.#dispatch(generalSlice.actions.updateHandRaisedIds(this.handRaisedIds))
    //     this.Events({
    //       type: "NOTIFY-RAISE-HAND",
    //       payload: {
    //         newHandRaisedIds: this.handRaisedIds,
    //         roomId: this.#roomId
    //       },
    //     });
    //   }
    // });
    //when createoffer is coming from another user
    this.connection.on("incomingoffer", async ({
      senderUsername,
      receiverUserName,
      senderEmail,
      sender,
      receiver,
      sdp,
      roomId,
      senderSocketId,
      receiverSocketId,
      senderAudioOn,
      senderCameraOn,
    }) => {
      // console.log("offer coming");
      const { mediaConnection, payload } =
        await this.#PeerConnections.createAnswer({
          senderUsername,
          receiverUserName,
          sender,
          receiver,
          senderSocketId,
          receiverSocketId,
          roomId,
          sdp,
          myStream: await this.Streams.getUserStreams(this.#userId),
          dispatch: this.#dispatch,
          updateStream: this.Streams,
          senderEmail,
          senderAudioOn,
          senderCameraOn,
        });
      this.onIceCandidate({
        userPeerConnection: mediaConnection,
        currentUser: this.#userId,
        newUserId: sender,
        roomId: this.#roomId,
        currentUserSocket: this.connection.id,
        newUserSocketId: senderSocketId,
      });
      this.Events({
        type: "CREATE_ANSWER",
        payload,
      });
    }
    );
    //when createAnswer is coming from another user
    this.connection.on("receiveoffer", async ({
      sender,
      receiver,
      sdp,
      roomI,
      senderSocketId,
      receiverSocketId,
    }) => {
      // console.log("receive offer")
      await this.#PeerConnections.setRemoteDescription({
        sdp,
        sender,
      });
    }
    );
    //when candidate is coming
    this.connection.on("candidate", async ({ sender, candidate }) => {
      // console.log("candidate cming")
      await this.#PeerConnections.addCandidates({
        candidate,
        sender,
      });
    });
    //when user disconnected or leave-call
    this.connection.on("user-disconnected", async ({ userId }) => {
      if (userId == this.#userId) return;
      await this.deleteUser({ userId });
      return
    });
    this.connection.on("force-leave", async ({ userId, roomId }) => {
      this.ForceLeaveCall({ userId, roomId })
      return
    });
    //when recive offer for screenshare ----stop for now
    this.connection.on("screen_share_incomingoffer", async ({
      sender,
      senderSocketId,
      sdp,
      roomId,
      receiver,
      receiverSocketId,
    }) => {
      const { screenShareConnection, payload } =
        await this.#PeerConnections.createAnswer_screenShare({
          receiver: sender,
          sender: receiver,
          senderSocketId: receiverSocketId,
          offer: sdp,
          streams: this.Streams,
          receiverSocketId: senderSocketId,
          dispatch: this.#dispatch,
        });
      this.onIceCandidate_screenShare({
        screenShareConnection,
        receiver: sender,
        receiverSocketId: senderSocketId,
      });
      this.Events({
        type: "SCREEN_SHARE_CREATE_ANSWER",
        payload,
      });
    }
    );
    //when candidate is cming for screenshare ----stop for now
    this.connection.on("screen_share_candidate", async ({ sender, candidate }) => {
      await this.#PeerConnections.screenShare_addCandidates({
        candidate,
        sender,
      });
    }
    );
    //when recieive offer for screen share ----stop for now
    this.connection.on("screen_share_receiveoffer", async ({ sender, sdp }) => {
      await this.#PeerConnections.screenShare_setRemoteDescription({
        sdp,
        sender,
      });
    });
    this.connection.on("cameraon", async ({ userId }) => {
      if (this.#userId == userId) return;

      this.#dispatch(
        roomSlice.actions.cameraOn({
          userId,
        })
      );
    });
    this.connection.on("cameraoff", async ({ userId }) => {
      if (this.#userId == userId) return;
      this.#dispatch(
        roomSlice.actions.cameraOff({
          userId,
        })
      );
    });
    this.connection.on("audioon", async ({ userId }) => {
      if (this.#userId == userId) return;
      this.#dispatch(
        roomSlice.actions.audioOn({
          userId,
        })
      );
    });
    this.connection.on("audiooff", async ({ userId }) => {
      if (this.#userId == userId) return;
      this.#dispatch(
        roomSlice.actions.audioOff({
          userId,
        })
      );
    });
    this.connection.on("screenoff", async ({ userId }) => {
      if (this.#userId == userId) return;
      //NOT ADDED:delete peerconnection of user who shared screen
      this.#dispatch(roomSlice.actions.stopScreenShare());
      this.#PeerConnections.deleteScreenShare({
        userId,
      });
      await this.Streams.deleteStream({
        userId,
        dispatch: this.#dispatch,
        screenShare: true,
      });
    });
    this.connection.on("disconnect", async () => {
      // console.log("this.#roomId",this.#roomId)
      this.#naviagte(`/disconnect`);
      this.LeaveCall();
    });
    this.connection.on("end-call", async ({ callInitializerId }) => {
      // console.log("this.#roomId",this.#roomId)
      callInitializerId !== this.#userId
        ? this.#naviagte(`/call-ended`)
        : this.#naviagte(`/leave-ended`)
      this.LeaveCall();
    });
    this.connection.on("notify-room-lock-update-reply", async ({ callInitializerId, isLock, roomId }) => {
      // console.log("this.#roomId",this.#roomId)
      // if (callInitializerId !== this.#userId) {
      this.#dispatch(roomSlice.actions.updateRoomLock({ isLock }));
      // }
    });
    this.connection.on("kick-user-reply", async ({ userToBeKickedId, roomId }) => {
      // console.log("this.#roomId",this.#roomId)
      if (userToBeKickedId === this.#userId) {
        this.#naviagte(`/removed`);
        this.LeaveCall();
      }
    });

  }

  async LeaveCall() {
    await this.Streams.deleteAllStream();
    this.Events({
      type: "LEAVE_CALL",
      payload: {
        userId: this.#userId,
        roomId: this.#roomId,
      },
    });
    await this.#PeerConnections.closeAllConnection();
    this.#dispatch(roomSlice.actions.LeaveCall());
    this.#dispatch(generalSlice.actions.callEnded());
  }
  async ForceLeaveCall({ userId, roomId }) {
    await this.Streams.deleteAllStream();
    this.Events({
      type: "LEAVE_CALL",
      payload: {
        userId,
        roomId
      },
    });
    await this.#PeerConnections.closeAllConnection();
    this.#dispatch(roomSlice.actions.LeaveCall());
    this.#dispatch(generalSlice.actions.callEnded());
  }
  async EndCallForAll(callInitializerId) {
    this.Events({
      type: "END-CALL-FOR-ALL",
      payload: {
        callInitializerId,
        roomId: this.#roomId
      },
    });
  }
  async NotifyRoomLockUpdate({ isLock, callInitializerId }) {
    this.Events({
      type: "NOTIFY-ROOM-LOCK-UPDATE",
      payload: {
        isLock,
        callInitializerId,
        roomId: this.#roomId
      },
    });
  }
  async NotifyRaiseHand(isHandRised, handRaisedIds, myId) {
    this.#dispatch(roomSlice.actions.updateIsHandRised(isHandRised))
    let newHandRaisedIds = [];
    if (isHandRised) {
      newHandRaisedIds = [...handRaisedIds, myId]
      this.#dispatch(generalSlice.actions.updateHandRaisedIds(newHandRaisedIds))
    }
    else {
      newHandRaisedIds = handRaisedIds.filter(id => id !== myId)
      this.#dispatch(generalSlice.actions.updateHandRaisedIds(newHandRaisedIds))
    }
    this.handRaisedIds = newHandRaisedIds;
    this.Events({
      type: "NOTIFY-RAISE-HAND",
      payload: {
        newHandRaisedIds,
        roomId: this.#roomId
      },
    });
  }
  async KickUser(userToBeKickedId) {
    this.Events({
      type: "KICK-USER",
      payload: {
        roomId: this.#roomId,
        userToBeKickedId
      },
    });
  }
  async deleteUser({ userId }) {
    await this.Streams.deleteStream({
      userId,
      dispatch: this.#dispatch,
    });
    await this.Streams.deleteStream({
      userId,
      dispatch: this.#dispatch,
      screenShare: true,
    });

    await this.#PeerConnections.closeConnection({ userId });
    this.#dispatch(roomSlice.actions.stopScreenShare());
  }
  async onIceCandidate_screenShare({
    screenShareConnection,
    receiver,
    receiverSocketId,
  }) {
    screenShareConnection.onicecandidate = async (event) => {
      if (!event.candidate) return;
      this.Events({
        type: "SCREEN_SHARE_CANDIDATE",
        payload: {
          sender: this.#userId,
          candidate: event.candidate,
          roomId: this.#roomId,
          senderSocketId: this.connection.id,
          receiver,
          receiverSocketId,
        },
      });
    };
  }
  async screenShareOn({ screenShare, users }) {
    this.#screenShare = true;
    await this.Streams.addStreams({
      userId: this.#userId,
      stream: screenShare,
      type: "video",
      screenShare: true,
    });
    for (let userId in users) {
      if (userId === this.#userId) continue;
      const { screenShareConnection, payload } =
        await this.#PeerConnections.createOffer_screenShare({
          userId: this.#userId,
          socketId: this.connection.id,
          stream: screenShare,
          roomId: this.#roomId,
          receiver: userId,
          receiverSocketId: users[userId].socketId,
        });
      this.onIceCandidate_screenShare({
        screenShareConnection,
        receiver: userId,
        receiverSocketId: users[userId].socketId,
      });
      this.Events({
        type: "SCREEN_SHARE_CREAET_OFFER",
        payload,
      });
    }

    this.#dispatch(roomSlice.actions.addScreenShare({ userId: this.#userId }));
  }
  async screenShareOff() {
    this.#screenShare = false;
    await this.Streams.deleteStream({
      userId: this.#userId,
      dispatch: this.#dispatch,
      screenShare: true,
    });
    this.Events({
      type: "SCREEN_SHARE_OFF",
      payload: {
        userId: this.#userId,
        roomId: this.#roomId,
      },
    });
    this.#dispatch(roomSlice.actions.stopScreenShare());
  }
  async UserCameraOn(newStream) {
    if (!this.#PeerConnections && newStream) return;
    this.#cameraOn = true;
    this.#PeerConnections.startVideoStream({ newStream });
    this.Events({
      type: "CAMERA_ON",
      payload: {
        userId: this.#userId,
        roomId: this.#roomId,
      },
    });
  }
  async UserCameraOff() {
    if (!this.#PeerConnections) return;
    this.#cameraOn = false;
    this.Events({
      type: "CAMERA_OFF",
      payload: {
        userId: this.#userId,
        roomId: this.#roomId,
      },
    });
  }
  async UserAudioOn() {
    if (!this.#PeerConnections) return;
    this.#audioOn = true;
    this.Events({
      type: "AUDIO_ON",
      payload: {
        userId: this.#userId,
        roomId: this.#roomId,
      },
    });
  }
  async UserAudioOff() {
    if (!this.#PeerConnections) return;
    this.#audioOn = false;
    this.Events({
      type: "AUDIO_OFF",
      payload: {
        userId: this.#userId,
        roomId: this.#roomId,
      },
    });
  }
}
