import JoinCall from "./components/JoinCall";
import React, { useEffect, useContext, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVideoStream } from "../store/actions";
import Styles from "./styles/JoinCall.style";
import "./styles/index.css";
import { BaseContext } from "../../../app/BaseConext";
import { checkRoom, getScheduleByIdExternal, updateScheduleMeetingTime } from "../../../data/api/Api";
import { useMediaQuery } from "react-responsive";
function Index() {
  const { Spinner, LoadingSpinIcon } = Styles;
  const { MyStreams } = useContext(BaseContext);

  const { roomId } = useParams();
  const streamLoading = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.streamLoading
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [room, setRoom] = useState({});
  // const [isRoomPassword, setisRoomPassword] = useState(false);
  // const [isPrivate, setisPrivate] = useState(false);
  // const [scheduleId, setScheduleId] = useState("");
  // const [memeberCountNow, setMemeberCountNow] = useState(0);
  // const [referenceType, setReferenceType] = useState(0);


  const TimeLeft = (enddatetime) => {
    const endDateTime = new Date(enddatetime).getTime();
    const now = new Date().getTime();
    const distance = now - endDateTime;
    // Return true if end time has not passed, otherwise false
    // console.log("DISTANCE:", distance);
    // console.log("END:", endDateTime);
    // console.log("NOW:", now);
    return distance > 0;
  }

  const deleteAllTracks = async () => {
    await MyStreams.getInstance().then(
      async ({ audioStream, videoStream }) => {
        const audioTracks = audioStream.getTracks();
        audioTracks.forEach((track) => {
          track.stop();
        });
        const videoTracks = videoStream.getTracks();
        videoTracks.forEach((track) => {
          track.stop();
        });
        navigate("/whoops");
      }
    );
  }
  const ismobile = useMediaQuery({ maxWidth: 450 });
  const isTablet = useMediaQuery({ minWidth: 768, minHeight: 1024 });
  const isGalaxyFold = useMediaQuery({ maxWidth: 717, maxHeight: 512 });
  useEffect(() => {

    if (ismobile) {
      const initialValue = document.body.style.zoom;
      const initialValue2 = document.body.style.overflow;

      // Change zoom level on mount
      document.body.style.overflow = "auto"
      document.body.style.flex = "none";
      // document.body.style.zoom = "90%";

    }
    else if (isTablet) {
      // document.body.style.zoom = "80%";
    }
    else if (isGalaxyFold) {
      // document.body.style.zoom = "80%";
      document.body.style.overflow = "auto"
    }
    else {
      document.body.style.flex = "flex";
      document.body.style.zoom = "100%";
    }
  }, [ismobile, isTablet, isGalaxyFold]);

  useEffect(() => {
    let mount = true;
    if (mount) {
      const data = checkRoom(roomId);
      data.then(async (response) => {
        setRoom(response)
        // setisRoomPassword(response.isRoomPassword);
        // setisPrivate(response.isPrivate);
        // setMemeberCountNow(response.memeberCountNow);
        // setReferenceType(response.referenceType)
        // console.log(response, "====CHECKROOM====")
        if (response?.isLock) {
          navigate("/room-locked")
          return;
        }
        if (response?.scheduleId) {
          // setScheduleId(response?.scheduleId)
          // const scheduleDetails = { startDate: '2023-11-11T09:23:19', endDate: '2023-11-11T10:10:19' }
          // const scheduleDetails = await getScheduleByIdExternal(response?.scheduleId) 
          // console.log("SCHEDULE ID OF THIS ROOM: ", response?.scheduleId)
          // console.log("scheduleDetails: ", scheduleDetails)
          // if (scheduleDetails?.endDate && TimeLeft(scheduleDetails?.endDate) === false) {
          //   navigate("/schedule-ended")
          //   return
          // }
        }
        if (!response) {
          await deleteAllTracks();
        } else {
          dispatch(getVideoStream(MyStreams));
        }
      });
    }
    return () => {
      mount = false;
    };
  }, []);

  if (streamLoading) return <Spinner style={{ height: "100vh" }} indicator={<LoadingSpinIcon />} />;
  // console.log("schedule testing", scheduleId)
  return <JoinCall
    room={room}
  // isRoomPassword={isRoomPassword}
  // isPrivate={isPrivate}
  // scheduleId={scheduleId}
  // memeberCountNow={memeberCountNow}
  // referenceType={referenceType}
  />;
}

export default Index;
