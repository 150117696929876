import Styles from "../styles/grid";
import { useContext, useState } from "react";
import { UserOutlined } from '@ant-design/icons';
import { Avatar, message } from 'antd';
import { useDispatch } from "react-redux";
import { BaseContext } from "../../../../app/BaseConext";
import { Button } from 'antd';
import { UpdateApprovalMember } from "../../../../data/api/Api";
import { useLocation } from "react-router-dom";
import { updateApprovalMembers } from "../../store/actions";

export function ParticipantApproval({ email }) {
    const dispatch = useDispatch();
    const {
        SidebarMemberRowMiddle,
        SidebarMemberRowLeft,
        SidebarMemberRowRight,
        SidebarMemberRow,
        SidebarMemberName,
        SidebarMemberRowLeftMiddle,
    } = Styles;
    const search = useLocation().search;
    const roomId = new URLSearchParams(search).get("room");
    const [messageApi, contextHolder] = message.useMessage();
    const [disableButton, setDisableButton] = useState(false);

    async function getUpdatedMembers() {
        dispatch(updateApprovalMembers(roomId))
    }

    const handleApproval = async (type, approvalStatus, notificationType, messageContent) => {
        setDisableButton(true);
        const data = {
            roomId,
            email,
            approvalStatus,
        };
        const resp = await UpdateApprovalMember(data);
        const notify = {
            type: notificationType,
            content: messageContent,
            duration: 2,
        };
        await messageApi.open(notify);
        getUpdatedMembers();
        setDisableButton(false);
    };

    const onClick = async (type) => {
        if (type === "accept") {
            handleApproval("accept", 1, 'success', `${email} Approval Granted`);
        } else if (type === "reject") {
            handleApproval("reject", 0, 'error', `${email} Approval Rejected`);
        }
    };

    return (
        <>
            {contextHolder}
            <SidebarMemberRow className="approvalMemberRow">
                <SidebarMemberRowLeftMiddle>
                    <SidebarMemberRowLeft>
                        <Avatar size={35} icon={<UserOutlined />} />
                    </SidebarMemberRowLeft>
                    <SidebarMemberRowMiddle>
                        <SidebarMemberName style={{ fontWeight: "500" }}>
                            {email}
                        </SidebarMemberName>
                    </SidebarMemberRowMiddle>
                </SidebarMemberRowLeftMiddle>
                <SidebarMemberRowRight>
                    <Button
                        disabled={disableButton}
                        onClick={() => { onClick("accept") }}
                        size="small"
                        style={{ marginLeft: "3px" }}
                        type="primary"
                    >
                        Accept
                    </Button>
                    <Button
                        disabled={disableButton}
                        onClick={() => { onClick("reject") }}
                        size="small"
                        style={{ marginLeft: "3px" }}
                        type="danger"
                    >
                        Reject
                    </Button>
                </SidebarMemberRowRight>
            </SidebarMemberRow>
        </>
    )
}