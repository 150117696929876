import React, { useRef, useContext, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  IoPersonCircleOutline,
  IoPersonOutline,
  IoLockClosedOutline,
  IoVideocamOutline,
  IoMailOutline,
  IoDocumentLockOutline,
} from "react-icons/io5";

import { Dropdown, Spin, message } from 'antd';
import 'antd/dist/antd.css';
import SpeakerChangeIcon from "../../../../assets/spaker-icon.svg";


import Styles from "../styles/JoinCall.style";
import { toggleVideoTracks, toggleAudioTracks } from "../../store/actions";
import { authCallStarted, authenticateUserToken, getApprovalMember, guestCallStarted, updateScheduleMeetingTime, updateScheduleMeetingTimeByExternal, updateScheduleMemberMeetingTime, updateScheduleMemberMeetingTimeByExternal } from "../../../../data/api/Api";
import { BaseContext } from "../../../../app/BaseConext";
import { generalSlice } from "../../../../domain/SharedSlices/generalslice/generalSlice";
import { getCookieValue, handleGetCookie, handleSetCookie } from "../../../../app/helper";
import { roomSlice } from "../../../call/store/slice";
import { FaCheckCircle } from "react-icons/fa";
const PrimaryColor = "#1a5669";
const SecondaryColor = "#ffff";
// const TertiaryColor = "#757d86";
// const BlackColor = "#000";
const {
  Spinner,
  LoadingSpinIcon,
  CallOptionButton,
  RoomPasswordView,
  InputLabel,
  InputGroupView,
  InputView,
  MainTitle,
  MainContainer,
  InputDiv,
  StartNowView,
  VideoDiv,
  VideoView,
  SideView,
  ChipButton,
  MethodeView,
  VideoOnIcon,
  MicOnIcon,
  VideoOffIcon,
  MicOffIcon,
  DeviceAlert,
  FloatingButton,
  BodyContainer,
  TopLogo,
  ToggleCameraIcon
} = Styles;



// const document = {
//   cookie: `token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI0N2Q3OTI5Yi01YjdkLTRmZmUtODU5ZC0yMDU5ZWNlMTMxOTEiLCJidXNpbmVzc0lkIjoiMGFiNWY5YzAtZjk0OC00YzQwLThkYWQtYzU4YmE5OWZiNzY1IiwianRpIjoiOTRmYTk0Y2QtMTg0Yy00ZDllLWI0MmItOWVkNGRkZTJkNjEzIiwibmJmIjoxNjg2ODI5MjMxLCJleHAiOjE2ODcwMDIwMzF9.eckJq42_yCaFp--CIT34wIP9RoI96PzkUhw1OPARCwFNXrVQ8YMzJnEL5nym4xXPtpW7A3S-AGCf1rGmXbTUTdboPJNceLuRlSPWA4ftEd8rWMd0gBwXHIQSFN8H2cxvlLpPIWMaKqHucZUD9gdBOSbY9c2sIxDoOqJoAf2VlsKNzW1pAvT2yzvFQiBKqdgyt-ncqLKGtmdGNYBq_r-Cc5_tagOBRtWxNpgZuDhtjQPdtO6cyF9b8qw-XLndMSJKLh9RWaM4glzFf2DztAD4kBKsVgHmHFhu0B61OLUBrsuaaeJPvYPbZT8aDJdCzSSngZj4-ebConDhvn2Ko7u66Q`
// }
// if (document.cookie) {
//   console.log("COOKIE" + getCookieValue('token'))
// }


function JoinCallRoom({ room }) {

  const {
    isPrivate = false,
    isRoomPassword = false,
    scheduleId = "",
    memeberCountNow = 0,
    referenceType = 3,
    meetingApprovalSwitch = true
  } = room
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const videoRef = useRef();
  const audioRef = useRef();
  const { roomId } = useParams();
  const { MyStreams, SocketConnection } = useContext(BaseContext);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  // const [email, setEmail] = useState("bari@miletap.com");
  // const [password, setPassword] = useState("WorkwP@$$7077");
  // const [name, setName] = useState("Abdul Bari");


  // const [name, setName] = useState((Math.random() + 1).toString(36).substring(7));
  // const [email, setEmail] = useState((Math.random() + 1).toString(36).substring(7) + "@abc.com");
  // const [password, setPassword] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [formMessage, setFormMessage] = useState("");
  const [guestUser, setGuestUser] = useState(!isPrivate);
  const [formRequired, setformRequired] = useState(false);
  const [roomPassword, setRoomPassword] = useState("");
  const [response, setResponse] = useState({});
  const [token, setToken] = useState("");
  const [waitingMessage, setWaitingMessage] = useState("");
  const [disableStartNow, setDisableStartNow] = useState(false);

  const camera = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.cameraOn
  );
  const audio = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.audioOn
  );
  const audioRequiredMessage = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.audioRequiredMessage
  );
  const cameraRequiredMessage = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.cameraRequiredMessage
  );
  const waitingCamera = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.waitingCamera
  );

  const approvalMessage = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.approvalMessage
  );
  const approvalWaiting = useSelector(
    ({ joinCallSlice: { uiState } }) => uiState.approvalWaiting
  );

  const currentVideoDeviceIndex = useSelector(
    ({ roomSlice: { uiState } }) => uiState.currentVideoDeviceIndex
  );
  const videoDevices = useSelector(
    ({ roomSlice: { uiState } }) => uiState.videoDevices
  );

  const currentOutputDevice = useSelector(
    ({ roomSlice }) => roomSlice.uiState.currentOutputDevice
  );

  // const [selectedOutputDevice, setSelectedOutputDevice] = useState(null);

  const [audioOutputDevices, setAudioOutputDevices] = useState([]);

  const handleMenuClick = (e) => {
    dispatch(roomSlice.actions.updateCurrentOutputDevice(e.key))
    // setSelectedOutputDevice(e.key)
  };

  const menuProps = {
    items: audioOutputDevices,
    onClick: handleMenuClick,
  };
  const menuEmptyProps = {
    items: [],
    onClick: () => { },
  };

  const handleChangeAudioOutput = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const audiooutput = devices.filter(device => device.kind === 'audiooutput');
      const newDevices = audiooutput?.map((device) => {
        const isSelected = currentOutputDevice === device.deviceId;
        const isDefault = 'default' === device.deviceId;
        return {
          'key': device.deviceId,
          'label': device.label,
          'icon': <FaCheckCircle color={isSelected || (isDefault && !currentOutputDevice) ? 'green' : 'gray'} />
        };
      });

      setAudioOutputDevices(newDevices);
    });
  };

  useEffect(() => {
    if (currentOutputDevice) {
      MyStreams.getInstance().then((instance) => {
        audioRef.current.srcObject = instance.audioStream;
        console.log(instance.audioStream)
        audioRef.current.setSinkId(currentOutputDevice)
          .then(() => {
            console.log(`Audio output set to ${currentOutputDevice}`);
            message.info('Audio Device changed');
          })
          .catch(error => {
            console.error('Error setting audio output:', error);
            message.info('Error setting audio output:' + error);
          });
      });
    }
  }, [currentOutputDevice]);


  const handleGuestCookies = () => {
    let guest = handleGetCookie('guest')
    if (guest?.length) {
      guest = JSON.parse(guest)
      console.log(guest)
      setName(guest.name);
      setEmail(guest.email);
    }
  }

  useEffect(() => {
    let mount = true;
    handleGuestCookies();
    if (mount) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const videoinput = devices.filter(device => device.kind === 'videoinput')
        dispatch(roomSlice.actions.updateVideoDevices(videoinput))
      })
      MyStreams.getInstance().then((instance) => {
        videoRef.current.srcObject = instance.videoStream;
        audioRef.current.srcObject = instance.audioStream;
      });
    }
    return () => {
      mount = false;
    };
  }, []);


  const toggleAudio = () => {
    dispatch(toggleAudioTracks({ MyStreams, SocketConnection }));
  };
  const toggleVideo = () => {
    if (!waitingCamera) {
      dispatch(toggleVideoTracks({ MyStreams, SocketConnection })).then(() => {
        dispatch(roomSlice.actions.updateCurrentVideoDeviceIndex(0))
      });
    }
  };

  const handleAuthenticationUser = () => {
    setGuestUser(false);
  };
  const handleGuestUser = () => {
    setGuestUser(true);
  };

  useEffect(() => {
    // console.log("======",guestUser,"=====");
    setFormMessage("")
    setformRequired(false)
    setDisableStartNow(false)
    return () => { };
  }, [guestUser]);

  const updateScheduleMeetingStarted = async (accessToken) => {
    if (scheduleId) {
      const access_token = query.get("token") ? query.get("token") : getCookieValue('token') || accessToken;
      // console.log("TESTING SCHEDULE", accessToken)
      // let token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI3NzU0Njc4Mi1hYTdhLTQ5ODQtOTM4OC01ZmQwNDRjMGZiMTEiLCJidXNpbmVzc0lkIjoiMGFiNWY5YzAtZjk0OC00YzQwLThkYWQtYzU4YmE5OWZiNzY1IiwidmVyIjoiRTVEOUJCREYtOTk1Ni00MUM3LTlFNUQtODNFNkI0RTVFQkM3IiwianRpIjoiNTFmNTNmZTQtOGYzYy00NWU2LWIwNmUtYzc0OTgzZTM2YTNkIiwibmJmIjoxNjk4NTE1MTYxLCJleHAiOjE2OTg2ODc5NjF9.BcNCeDS0Huu-1myQAM-imjAi4gF3IbesdUvRaZb5qAxkoAloxwMTYkdYdL3ktsOkoYWUnD-HNfhIqvuz7R_KcSjRrp2NKm6D0rYGwOvGwAKPrKQwA73o7iYm9GuyTHC-N8xwGq5Wel8zlzZPHyFnB4QX9qXrUJqAWh-dj6H3enua57RWt0gi9KOdcz2Jky9xqzBaxvaVniODeEXWCHAy45TvCTtSGalemqvNdrYoI0Z_RAl6hA7Cw5od4RmqjNo368vFLfRYashW4ZpU4J1rB9urB2TU6aIWubGIfSxFFc_5owFzdjZoytxBeCC8z-ol6mscyWcm5Z7bZ7x_qIjAsw"
      const response = access_token ?
        await updateScheduleMemberMeetingTime(access_token, scheduleId, true) :
        await updateScheduleMemberMeetingTimeByExternal(scheduleId, true, email);
      // console.log("query.get:", query.get("token"));
      // console.log("token", getCookieValue('token'));
      if (access_token) {
        // console.log("updateScheduleMemberMeetingTime", response);
      }
      else {
        // console.log("updateScheduleMemberMeetingTimeByExternal", response);
      }
      // console.log(memeberCountNow + "----------------------memeberCountNow----------------------")
      if (!memeberCountNow && access_token) {
        // const response = await updateScheduleMeetingTime(access_token, scheduleId, true);
        // console.log("updateScheduleMeetingTime when no user in meeting", response);
      }
      else if (!memeberCountNow && !access_token) {
        // const response = await updateScheduleMeetingTimeByExternal(scheduleId, true, email);
        // console.log("updateScheduleMeetingTimeByExternal when no user in meeting", response);
      }
    }
  }

  const handleCameraSwitch = async () => {
    try {
      if (videoDevices.length > 1) {
        const newIndex = currentVideoDeviceIndex === 0 ? 1 : 0;

        MyStreams.switchCamera(newIndex).then((instance) => {
          videoRef.current.srcObject = instance.videoStream;
          audioRef.current.srcObject = instance.audioStream;
          dispatch(roomSlice.actions.updateCurrentVideoDeviceIndex(newIndex))
        });
      }
    }
    catch (error) {

    }
  };


  useEffect(() => {
    if (getCookieValue('token') || query.get("token")) {
      (async () => {
        let response;
        let token = "";
        if (query.get("token")) {
          token = query.get("token");
          response = await authenticateUserToken(query.get("token"));
        }
        else {
          token = getCookieValue('token')
          response = await authenticateUserToken(token)
        }
        if (!response) {
          navigate("/unauthorized");
          return;
        }
        setResponse(response);
        setToken(token);
      })();
    }
  }, []);

  useEffect(() => {
    dispatch(roomSlice.actions.updateMeetingApprovalSwitch(meetingApprovalSwitch))
    SocketConnection.getSocket().then(async (socketId) => { });
    return () => { };
  }, []);


  useEffect(() => {
    if (query.get("isVideo") == 0) {
      toggleVideo();
    }
    (async () => {
      if (response && token) {
        if ((getCookieValue('token') || query.get("token")) && !isRoomPassword) {
          const Email = response.email;
          const Name = response.firstName + ' ' + response.lastName;
          const data = await guestCallStarted({
            userId: response.id,
            accessToken: token,
            email: Email,
            name: Name,
            roomPassword,
            roomId,
            isRoomPassword,
          });
          // console.log(data, "1====================1")
          updateScheduleMeetingStarted(token);
          // console.log(data, "2====================2")
          if (!data) {
            // console.log(data, "=======!!!!!!!!!!!!=============")
          }
          handleFormMessage(data)
          setformRequired(false);
          dispatch(
            generalSlice.actions.callStarted({
              data,
              email: Email,
              name: Name,
              scheduleId,
              roomId,
            })
          );
          dispatch(generalSlice.actions.updateTokenQueryOrCookie(token));
          navigate(`/room?room=${roomId}`);
          return
        }
        else if ((getCookieValue('token') || query.get("token")) && isRoomPassword) {
          setEmail(response.email);
          setName(response.firstName + ' ' + response.lastName);
        }
      }
    })();

  }, [response, token]);

  const handleFormMessage = (data) => {
    if (data === "Email already Exist") {
      setFormMessage("Someone already join with " + (response?.email ? response?.email : " this Email ."));
      setformRequired(true);
      return;
    }
    if (data === "Check Credentials roomID or roomPassword" || data === "Room not created Yet") {
      setFormMessage("Room credentials are incorrect or Room not Created yet!");
      setformRequired(true);
      return;
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,6})+$/;
    if (!email || !emailRegex.test(email)) {
      setFormMessage("Email is incorrect.");
      setformRequired(true);
      setDisableStartNow(false);
      return false;
    }
    return true;
  };

  const validatePassword = (password) => {
    if (!password) {
      setFormMessage("Please type Password first.");
      setformRequired(true);
      setDisableStartNow(false);
      return false;
    }
    return true;
  };

  const validateRoomPassword = (isRoomPassword, roomPassword) => {
    if (isRoomPassword && !roomPassword) {
      setFormMessage("Room Password is incorrect.");
      setformRequired(true);
      setDisableStartNow(false);
      return false;
    }
    return true;
  };

  const validateGuestUser = (name, email, isRoomPassword, roomPassword) => {
    if (!name) {
      setFormMessage("Name is required.");
      setformRequired(true);
      setDisableStartNow(false);
      return false;
    }
    if (!validateEmail(email)) return false;
    if (!validateRoomPassword(isRoomPassword, roomPassword)) return false;
    return true;
  };

  const proceedToCall = async () => {
    const data = await guestCallStarted({
      userId: "",
      accessToken: "",
      email,
      name,
      roomPassword,
      roomId,
      isRoomPassword,
    });
    console.log("data guest", data);
    if (data?.response?.data) {
      setFormMessage(data.response.data);
      setformRequired(true);
      setDisableStartNow(false);
      return;
    }
    handleFormMessage(data);
    dispatch(
      generalSlice.actions.callStarted({
        data,
        email,
        scheduleId,
        name,
        roomId,
      })
    );
    updateScheduleMeetingStarted("");

    if (!data.roomData.isPrivate) {
      navigate(`/room?room=${roomId}`);
    }

    setDisableStartNow(false);
  }
  useEffect(() => {
    // console.log("==approvalMessage==:", approvalMessage);
    // console.log("==approvalWaiting==:", approvalWaiting)
    setFormMessage(approvalMessage);
    setformRequired(approvalWaiting)
    setDisableStartNow(approvalWaiting);
    if (!approvalWaiting && approvalMessage === "Approval Granted") {
      (async () => {
        await proceedToCall();
      })();
    }
  }, [approvalWaiting, approvalMessage]);


  const handleStartNow = async () => {
    handleSetCookie('guest', JSON.stringify({ name, email }));
    setDisableStartNow(true);

    if (guestUser) {
      if (email.includes('miletap.com')) {
        setFormMessage("Please join with Workwise authentication.");
        setformRequired(true)
        setDisableStartNow(true);
        return
      }
      if (!validateGuestUser(name, email, isRoomPassword, roomPassword)) return;

      console.log("meetingApprovalSwitch:", meetingApprovalSwitch);
      if (referenceType == 3 || !meetingApprovalSwitch) {
        await proceedToCall();
        return
      }

      const userApproval = await getApprovalMember(roomId, email)
      if (userApproval == 0) {
        setFormMessage("Approval rejected by admin");
        setformRequired(true)
        setDisableStartNow(true)
        return
      }
      if (userApproval == 1) {
        await proceedToCall();
        return
      }
      if (userApproval == 2) {
        setFormMessage("Please wait , while Admin lets you in.");
        setformRequired(true)
        setDisableStartNow(true)
        return
      }

      const payload = {
        roomId,
        email,
        userId: ""
      }
      const socketResponse = await SocketConnection.Events({ type: 'REQUEST_ROOM_ENTRY', payload: payload });
      // console.log("socketResponse", socketResponse);
      return

    } else {
      if (!validateEmail(email)) return;
      if (!validatePassword(password)) return;
      if (!validateRoomPassword(isRoomPassword, roomPassword)) return;

      const data = await authCallStarted({
        email,
        password,
        roomPassword,
        roomId,
        isRoomPassword,
      });

      console.log("data authenticate", data);
      if (data?.status !== 200) {
        setFormMessage(data?.response?.data);
        setformRequired(true);
        setDisableStartNow(true);
        return;
      }

      console.log("USER ID: ", data.data.userData.userId);
      const payload = {
        roomId,
        email,
        userId: data.data.userData.userId
      }
      const socketResponse = await SocketConnection.Events({ type: 'REQUEST_ROOM_ENTRY', payload: payload });
      // console.log("socketResponse Auth", socketResponse);
      setformRequired(false);

      dispatch(
        generalSlice.actions.callStarted({
          data: data.data,
          email,
          scheduleId,
          name,
          roomId,
        })
      );

      updateScheduleMeetingStarted(data.data.userData.accessToken);
      navigate(`/room?room=${roomId}`);
      setDisableStartNow(false);
    }
  };



  return ((getCookieValue('token') || query.get("token")) && !isRoomPassword)
    ?
    <div style={{ background: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100vh" }}>
      <Spinner indicator={<LoadingSpinIcon />} />
      <h4 style={{ marginTop: "50px", display: "flex", justifyContent: "center", alignItems: "center", background: "black", color: "white", width: "100%" }}>{formMessage}</h4>
    </div>
    :
    <MainContainer>
      {
        (audioRequiredMessage || cameraRequiredMessage) &&
        <DeviceAlert
          width="50%"
          message={`Please Allow ${audioRequiredMessage && cameraRequiredMessage
            ? "Camera and Mic"
            : cameraRequiredMessage
              ? "Camera"
              : audioRequiredMessage
                ? "Mic"
                : ""
            } Permissions`}
          type="warning"
          showIcon
          closable
          absolute={true}
        />
      }
      <TopLogo>
        <img
          src="logo192.png"
          style={{
            width: "20px",
          }}
          alt=""
        />
        <div style={{ fontSize: "15px", fontWeight: "500" }}>
          Workwise
        </div>
      </TopLogo>
      <BodyContainer>
        <VideoDiv style={{ marginTop: "5%" }}>

          <VideoView currentDeviceIndex={currentVideoDeviceIndex} ref={videoRef} autoPlay muted playsInline loop />
          <audio ref={audioRef} autoPlay muted></audio>
          <CallOptionButton style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={handleChangeAudioOutput}
              className={audio ? "custom-select-parent" : "custom-select-parent-gray"}>
              <Dropdown.Button
                menu={audio ? menuProps : menuEmptyProps}
                icon={
                  <img src={SpeakerChangeIcon}
                    className="custom-select" alt="" />

                }
                trigger={audio ? 'onclick' : ''} />
            </div>
            <FloatingButton onClick={toggleAudio}>
              {audio ? (
                <MicOnIcon color={SecondaryColor} />
              ) : (
                <MicOffIcon color={SecondaryColor} />
              )}
            </FloatingButton>
            {
              videoDevices.length > 1 &&
              <FloatingButton
                onClick={handleCameraSwitch}
                disabled={!camera}
                style={!camera ? { backgroundColor: "gray", borderColor: "gray" } : {}}>
                <ToggleCameraIcon color="white" />
              </FloatingButton>
            }

            <FloatingButton onClick={toggleVideo}>
              {camera ? (
                <VideoOnIcon color="white" />
              ) : (
                <VideoOffIcon color="white" />
              )}
            </FloatingButton>
          </CallOptionButton>
        </VideoDiv>

        <SideView>

          <MainTitle>Ready to join ?</MainTitle>
          {formRequired && formMessage ? (
            <DeviceAlert
              message={`${formMessage}`}
              type="warning"
              showIcon
              // closable
              width="80%"
              onClose={() => {
                setformRequired(false);
              }}
            />
          ) : (
            <></>
          )}
          {!isPrivate
            && (
              <MethodeView>
                {
                  !getCookieValue('token') && !query.get("token") ?
                    <>
                      <ChipButton
                        paddingVertical="5px"
                        borderRadius="10px"
                        width="100%"
                        active={!guestUser}
                        onClick={handleAuthenticationUser}
                      >
                        <img
                          src="logo192.png"
                          alt=""
                          style={{
                            width: "25px",
                          }}
                        />
                        Authentication
                      </ChipButton>
                      <ChipButton
                        paddingVertical="5px"
                        borderRadius="10px"
                        width="100%"
                        onClick={handleGuestUser}
                        active={guestUser}
                      >
                        <IoPersonCircleOutline
                          size="24px"
                          style={{
                            marginTop: "2px",
                          }}
                        />
                        Guest
                      </ChipButton>
                    </> :
                    <></>
                }

              </MethodeView>
            )
          }
          {
            !getCookieValue('token') && !query.get("token") ?
              <InputGroupView>
                {!isPrivate && guestUser ? (
                  <>
                    {/* <InputLabel>Full Name</InputLabel> */}
                    <InputDiv>
                      <IoPersonOutline
                        color={PrimaryColor}
                        style={{
                          position: "absolute",
                          fontSize: "20px",
                          marginTop: "12px",
                          marginLeft: "10px",
                        }}
                      />
                      <InputView
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                        color={PrimaryColor}
                        placeholder="Full Name"
                      />
                    </InputDiv>
                  </>
                ) : (
                  <></>
                )}
                {/* <InputLabel>Email</InputLabel> */}
                <InputDiv>
                  <IoMailOutline
                    color={PrimaryColor}
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginTop: "14px",
                      marginLeft: "10px",
                    }}
                  />
                  <InputView
                    type="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    color={PrimaryColor}
                    placeholder="Your Email"
                  />
                </InputDiv>
                {guestUser && !isPrivate ? (
                  <></>
                ) : (
                  <>
                    {/* <InputLabel>Password</InputLabel> */}
                    <InputDiv>
                      <IoLockClosedOutline
                        color={PrimaryColor}
                        style={{
                          position: "absolute",
                          fontSize: "20px",
                          marginTop: "12px",
                          marginLeft: "10px",
                        }}
                      />
                      <InputView
                        type="password"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                        color={PrimaryColor}
                        placeholder="Your Password"
                      />
                    </InputDiv>
                  </>
                )}
              </InputGroupView> :
              <></>
          }
          <StartNowView>
            {isRoomPassword && (
              <RoomPasswordView>
                <InputDiv
                  style={{
                    width: "100%",
                  }}
                >
                  <IoDocumentLockOutline
                    color={PrimaryColor}
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      marginTop: "14px",
                      marginLeft: "10px",
                    }}
                  />
                  <InputView
                    type="password"
                    value={roomPassword}
                    onChange={(event) => {
                      setRoomPassword(event.target.value);
                    }}
                    color={PrimaryColor}
                    placeholder="Your Room Password"
                  />
                </InputDiv>
              </RoomPasswordView>
            )}
            <ChipButton
              disabled={disableStartNow}
              paddingVertical="5px"
              borderRadius="10px"
              width="100%"
              onClick={handleStartNow}
              type="submit"
            >
              {
                disableStartNow &&
                <Spin size="medium" />
              }
              <IoVideocamOutline
                size="20px"
                style={{
                  // marginTop: "2px",
                }}
              />
              Start Now
            </ChipButton>
            {/* </InputDiv> */}
          </StartNowView>
        </SideView>
      </BodyContainer>
    </MainContainer>

}
export default JoinCallRoom;
