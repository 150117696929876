import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store";
import Call from "../features/call/ui/index";
import JoinCall from "../features/join_call/ui/index";
import BaseContextProvider from "./BaseConext";
import WrongRoom from "../features/WrongRoom/WrongRoom";
import ResultComponent from "../features/ResultComponent/ResultComponent";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_ROOT_DIRECTORY_PATH}>
        <Routes>
          <Route path="/" element={<WrongRoom />} />
          <Route path="/whoops" element={<WrongRoom />} />
          <Route
            path="/*"
            element={
              <BaseContextProvider>
                <Routes>
                  <Route path=":roomId" element={<JoinCall />} />
                  <Route path="room/*" element={<Call />} />
                </Routes>
              </BaseContextProvider>
            }
          />
          <Route
            path="/leave"
            element={<ResultComponent title="You have left the meeting. " />}
          />
          <Route
            path="/leave-ended"
            element={<ResultComponent title="Call has ended and you have left the meeting. " />}
          />
          <Route
            path="/room-locked"
            element={<ResultComponent title="Room is Locked, please contact the call administrator.  " />}
          />
          <Route
            path="/disconnect"
            element={<ResultComponent title="Meeting ended. You have lost Internet connection. " reconnect={true} />}
          />
          <Route
            path="/call-ended"
            element={<ResultComponent title="Call has been ended by the administrator. You can no longer join the room. " />}
          />
          <Route
            path="/removed"
            element={<ResultComponent title="You have been removed from the meeting, please contact the call administrator. " />}
          />
          <Route
            path="/unauthorized"
            element={<ResultComponent title="Security Verification Failed , Unauthorized Access. " />}
          />
          <Route
            path="/schedule-ended"
            element={<ResultComponent title="Scheduled call has been ended by the administrator. You can no longer join the room. " />}
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
